// export const APIBASEURL = "http://localhost:5000";
export const APIBASEURL = "https://ws-glam-server-ba7e0a4c360b.herokuapp.com";

export const apiUrls = {
  //User + Auth
  createUser: "/users",
  createUserWithGoogle: "/users/create-user-with-google",
  signInUser: "/users/login",
  updateUser: "/users/",
  forgotPassword: "/users/forgot-password",
  resetPassword: "/users/reset-password/",
  addEmail: "/email",
  contactFormSubmission: "/email/contact",
  //Category
  getAllCategory: "/category/all",
  getSubCategoryByCategory: "/sub-category/by-category/",
  getAllSubCategory: "/sub-category/all",
  //Product
  getAllProducts: "/product",
  getOneProduct: "/product/",
  addProductReview: "/product/add-review/",
  getRealatedProducts: "/product/related-products/",
  //Order urls
  getOneOrder: "/order/",
  createOrder: "/order",
  getOrdersByUserId: "/order/getOrdersByUserId/",
  checkCouponValidity: "/coupon/check-validity/",
  //Payfast url
  updatePaymentInOrder: "/updatePaymentInOrder",
  paymentFailure: "/paymentFailure",
  //Card Urls
  addCard: "/ci",
  //Brand Urls
  getAllBrands: "/brand/all",
};

export const deliveryCharges = 0;
export const appName = "WS Glam";
